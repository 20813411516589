import React, { Component } from "react";
import "./error-page.scss";
//import MainErrors from "../../data/main-errors.json";
//import SubErrors from "../../data/sub-errors.json";
import TopBar from "../../components/TopBar/top-bar";
import DecorTitle from "../../components/DecorTitle/decor-title";
import Paragraph from "../../components/Paragraph/paragraph";
import Hexagon from "../../components/Hexagon/hexagon";
import PDFModal from "../../components/PDFModal/pdf-modal";
import AppDataContext from "../../context/appDataContext";
class ErrorPage extends Component {
  static contextType = AppDataContext;
  state = {
    mainErrorId: this.props.match.params.main_error_id,
    subErrorId: 2,
    flowHistory: [],
    pdfOpen: false,
    pdfName: null,
  };
  render() {
    const MainErrors = this.context.Data.MainErrors;
    const SubErrors = this.context.Data.SubErrors;
    const subErrors = SubErrors.find(
      (error) =>
        error.category_name == this.props.match.params.category_name &&
        error.main_error_id == this.state.mainErrorId
    ).sub_errors;

    const subError = subErrors.find(
      (error) => error.id == this.state.subErrorId
    );

    return (
      <div className="error-page">
        <PDFModal
          open={this.state.pdfOpen}
          pdfName={this.state.pdfName}
          close={this.togglePDF}
        />
        <TopBar />
        <DecorTitle
          text={
            this.props.match.params.category_name +
            " | " +
            MainErrors.find(
              (error) =>
                error.category_name == this.props.match.params.category_name
            ).main_errors.find((error) => {
              return error.id == this.state.mainErrorId;
            }).title
          }
        />
        <Paragraph>{subErrors[0].question}</Paragraph>
        <div className="flow">
          <div>
            {this.state.flowHistory.map((error, index) => {
              return (
                // SubError
                <div key={index} style={{ marginBottom: "25px" }}>
                  <div
                    className="fade-in"
                    style={{ marginBottom: "5px", display: "flex" }}
                  >
                    <Hexagon number={index + 1} />
                    <div
                      dangerouslySetInnerHTML={{ __html: error.question }}
                      style={{ color: "#c9c9c9", marginLeft: "10px" }}
                    ></div>
                  </div>

                  <div
                    dangerouslySetInnerHTML={{ __html: error.answer }}
                    style={{ color: "#999", marginLeft: "30px" }}
                  ></div>
                </div>
              );
            })}
            <div style={{ paddingTop: "10px", display: "flex" }}>
              <Hexagon current number={this.state.flowHistory.length + 1} />

              <div
                style={{ marginLeft: "10px", marginRight: "10px" }}
                dangerouslySetInnerHTML={{ __html: subError.question }}
              ></div>
              {subError.pdf_name && (
                <span
                  onClick={() => this.togglePDF(subError.pdf_name)}
                  className="view-pdf-button"
                >
                  ?
                </span>
              )}
            </div>
          </div>
        </div>
        <div className="buttons" ref={(ref) => (this.newData = ref)}>
          {subError.next_yes_id && (
            <button
              onClick={() => {
                this.addFlowHistory(
                  subError.id,
                  subError.question,
                  subError.yes_text,
                  subError.next_yes_id
                );
              }}
            >
              {subError.yes_text}
            </button>
          )}

          {subError.next_no_id && (
            <button
              onClick={() =>
                this.addFlowHistory(
                  subError.id,
                  subError.question,
                  subError.no_text,
                  subError.next_no_id
                )
              }
            >
              {subError.no_text}
            </button>
          )}

          {subError.next_question_id && (
            <button
              onClick={() =>
                this.addFlowHistory(
                  subError.id,
                  subError.question,
                  subError.next_text,
                  subError.next_question_id
                )
              }
            >
              {subError.next_text}
            </button>
          )}

          {!subError.next_question_id &&
            !subError.next_yes_id &&
            !subError.next_no_id && (
              <button onClick={() => this.props.history.go(-1)}>
                Return to Search
              </button>
            )}
        </div>
      </div>
    );
  }

  componentDidUpdate() {
    this.newData.scrollIntoView({ behavior: "smooth" });
  }

  addFlowHistory = (sub_error_id, question, answer, next_question_id) => {
    this.setState({
      flowHistory: [
        ...this.state.flowHistory,
        { sub_error_id: sub_error_id, question: question, answer: answer },
      ],
      subErrorId: next_question_id,
    });

    // If moving to next category
    if (next_question_id.indexOf("^") != -1) {
      this.setState({
        mainErrorId: next_question_id.split("")[1],
        subErrorId: 2,
      });
    }
  };

  togglePDF = (name) => {
    if (this.state.pdfOpen) this.setState({ pdfOpen: false });
    else this.setState({ pdfOpen: true, pdfName: name });
  };
}

export default ErrorPage;
