import React from "react";

const AppDataContext = React.createContext({
  goINI: () => {},
  IsLoggedIn: false,
  FullName: "",
  Data: {
    ErrorCategories: [],
    SubErrors: [],
    MainErrors: [],
  },
});

export const AppDataProvider = AppDataContext.Provider;
export const AppDataConsumer = AppDataContext.Consumer;
export default AppDataContext;
