import React, { Component } from "react";
import "./errors-page.scss";
import TopBar from "../../components/TopBar/top-bar";
import DecorTitle from "../../components/DecorTitle/decor-title";
import Paragraph from "../../components/Paragraph/paragraph";
import SearchInput from "../../components/Search/search-input";
import MainError from "../../components/MainError/main-error";
//import MainErrors from "../../data/main-errors.json";
import AppDataContext from "../../context/appDataContext";
class ErrorsPage extends Component {
  static contextType = AppDataContext;
  state = {
    showErrors: false,
    searchString: "",
  };

  render() {
    const MainErrors = this.context.Data.MainErrors;
    const filteredMainErrors =
      this.state.searchString.length > 0
        ? MainErrors.find(
            (error) =>
              error.category_name == this.props.match.params.category_name
          ).main_errors.filter((error) => {
            if (
              error.title
                .replace(/<[^>]*>/g, "")
                .toUpperCase()
                .indexOf(this.state.searchString.toUpperCase()) != -1 ||
              error.description
                .replace(/<[^>]*>/g, "")
                .toUpperCase()
                .indexOf(this.state.searchString.toUpperCase()) != -1 ||
              error.error_messages
                .replace(/<[^>]*>/g, "")
                .toUpperCase()
                .indexOf(this.state.searchString.toUpperCase()) != -1 ||
              error.symptoms
                .replace(/<[^>]*>/g, "")
                .toUpperCase()
                .indexOf(this.state.searchString.toUpperCase()) != -1
            )
              return true;
            return false;
          })
        : MainErrors.find(
            (error) =>
              error.category_name == this.props.match.params.category_name
          ).main_errors;

    return (
      <div className="errors-page">
        <TopBar />
        <DecorTitle
          text={this.props.match.params.category_name + " | Malfunctions"}
        />
        <Paragraph>
          {this.state.showErrors
            ? "Please select or search for the malfunction you want to solve:"
            : "Please search for the malfunction you want to solve"}
        </Paragraph>

        <div style={{ padding: "0px 30px" }}>
          <SearchInput
            value={this.state.searchString}
            name="searchString"
            onChange={(e) => this.onChange(e)}
            style={{ marginTop: "35px", marginBottom: "15px" }}
          />

          {!this.state.showErrors && (
            <div className="search-subtext" onClick={this.showErrors}>
              Click here to see the full list of the malfunctions
              <img src={require("../../images/teal-arrow-right.png")} />
            </div>
          )}
        </div>

        {/* <br /> */}
        <div style={{ paddingBottom: "20px" }}></div>

        <div>
          {(this.state.searchString.length > 0 || this.state.showErrors) &&
            filteredMainErrors.map((error) => {
              return (
                <MainError
                  key={error.id}
                  id={error.id}
                  title={error.title}
                  text={error.description}
                />
              );
            })}
        </div>
      </div>
    );
  }

  showErrors = () => {
    this.setState({ showErrors: true });
  };

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
}

export default ErrorsPage;
