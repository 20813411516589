import React, { Component } from "react";
import "./top-bar.scss";
import { withRouter } from "react-router-dom";
import Sidebar from "../Sidebar/sidebar";

class TopBar extends Component {
  state = {
    showSidebar: false,
  };
  render() {
    return (
      <React.Fragment>
        <div className="top-bar">
          {this.props.back !== false ? (
            <img
              onClick={this.goBack}
              className="back-arrow"
              src={require("../../images/back-arrow.png")}
            />
          ) : (
            <span className="spacer"></span>
          )}
          <img
            onClick={() => this.props.history.push("/choose-error-category")}
            className="logo"
            src={require("../../images/top-bar-logo.png")}
          />
          {/* <img
            className="contact-us"
            src={require("../../images/contact-us.png")}
          /> */}
          <img
            onClick={this.toggleSidebar}
            className="hamburger"
            src={require("../../images/hamburger.png")}
          />
        </div>
        <Sidebar show={this.state.showSidebar} />
        <div style={{ paddingTop: "80px" }} />
      </React.Fragment>
    );
  }

  toggleSidebar = () => {
    this.setState({ showSidebar: !this.state.showSidebar });
  };

  goBack = () => {
    this.props.history.goBack();
  };
}

export default withRouter(TopBar);
