import React, { useState } from "react";
import "./pdf-modal.scss";
import { Document, Page, pdfjs } from "react-pdf";

export default function PDFModal(props) {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  //pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  //pdfjs.GlobalWorkerOptions.workerSrc = require("../../libs/pdfjs");
  pdfjs.GlobalWorkerOptions.workerSrc =
    process.env.PUBLIC_URL + "/pdf.worker.js";

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  return props.open ? (
    <div className="pdf-modal">
      <div onClick={props.close} className="close-button">
        <img src={require("../../images/close.png")} width="25px" />
      </div>

      <Document
        className="document"
        file={`/files/PDF/${props.pdfName}.pdf`}
        onLoadSuccess={onDocumentLoadSuccess}
      >
        <Page
          className="page"
          width={window.innerWidth}
          height={window.innerHeight}
          pageNumber={pageNumber}
        />
      </Document>
      <div className="nav-buttons">
        <button
          disabled={pageNumber == 1}
          onClick={() => setPageNumber(pageNumber - 1)}
          className="prev-button"
        >
          {"<"}
        </button>
        Page {pageNumber} of {numPages}
        <button
          disabled={pageNumber == numPages}
          onClick={() => setPageNumber(pageNumber + 1)}
          className="next-button"
        >
          {">"}
        </button>
      </div>
    </div>
  ) : null;
}
