import React, { Component } from "react";
import "./decor-title.scss";

class DecorTitle extends Component {
  state = {};
  render() {
    return (
      <div className="decor-title">
        <img className="decor" src={require("../../images/title-decor.png")} />
        <span className="text">{this.props.text}</span>
      </div>
    );
  }
}

export default DecorTitle;
