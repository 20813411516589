import React, { Component } from "react";
import "./sidebar.scss";
//import ErrorCategories from "../../data/error-categories.json";
import { Link, withRouter } from "react-router-dom";
import AppDataContext from "../../context/appDataContext";
class Sidebar extends Component {
  static contextType = AppDataContext;
  state = {};
  render() {
    const ErrorCategories = this.context.Data.ErrorCategories;
    return (
      <div className={"sidebar " + (this.props.show ? "show" : "hide")}>
        <ul>
          {ErrorCategories.map((errorCategory) => {
            return (
              <li key={errorCategory.id} className="printer-button">
                <Link to={"/errors/" + errorCategory.category_name}>
                  {errorCategory.category_name}
                </Link>
              </li>
            );
          })}
          <li key={"asd"} className="printer-button">
            <Link to={"/logout"}>Logout</Link>
          </li>
          <li>
            <div className="contact-us-wrapper">
              <span>We invite you to share your feedback with us</span>
              <a href="mailto:Tal.Raz@kornit.com">
                <img
                  className="envelope-icon"
                  src={require("../../images/envelope.png")}
                />
                FEEDBACK
              </a>
            </div>
          </li>
        </ul>
      </div>
    );
  }
}

export default withRouter(Sidebar);
