import React, { Component } from "react";
import "./main-error.scss";
import { withRouter } from "react-router-dom";

class MainError extends Component {
  state = {};
  render() {
    return (
      <div
        className="main-error"
        onClick={() =>
          this.props.history.push(
            this.props.match.params.category_name +
              "/" +
              this.props.id +
              "/" +
              this.props.title
          )
        }
      >
        <span
          className="title"
          dangerouslySetInnerHTML={{
            __html: this.props.title,
          }}
        />
        <br />
        <div style={{ margin: "10px 0px" }} />
        <span
          className="text"
          dangerouslySetInnerHTML={{
            __html: this.props.text,
          }}
        />
      </div>
    );
  }
}

export default withRouter(MainError);
