import React, { Component, useState, useEffect, useContext } from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { useRouteMatch } from "react-router";
import AppDataContext from "../../context/appDataContext";
import "./logout-page.scss";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Api from "../../utils/api";

const Logout = (props) => {
  const AppData = useContext(AppDataContext);
  useEffect(() => {
    Api.get("Logout").then((res) => {
      AppData.goINI();
      props.history.push("/");
    });
  }, []);

  return (
    <div className="login-page">
      <div className="upper-half">
        <img className="logo" src={require("../../images/login-logo.png")} />
      </div>
      <div className="lower-half">
        <div className="lower-half-content"></div>
      </div>
    </div>
  );
};

export default Logout;
