import React, { Component } from "react";
import "./paragraph.scss";

class Paragraph extends Component {
  state = {};
  render() {
    return (
      <div
        style={this.props.style}
        dangerouslySetInnerHTML={{
          __html: this.props.children,
        }}
        className="paragraph"
      />
    );
  }
}

export default Paragraph;
