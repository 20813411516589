import React, { Component } from "react";
import TopBar from "../../components/TopBar/top-bar";
import "./choose-error-category-page.scss";
//import ErrorCategories from "../../data/error-categories.json";
import AppDataContext from "../../context/appDataContext";
class ChooseErrorCategoryPage extends Component {
  static contextType = AppDataContext;
  state = {};
  render() {
    const ErrorCategories = this.context.Data.ErrorCategories;

    return (
      <div className="choose-error-category-page">
        <TopBar back={false} />
        <span className="title">Hello {localStorage.getItem("name")}</span>
        <span className="sub-title">Please select the relevant printer</span>
        {ErrorCategories.map((category, index) => {
          return (
            <img
              key={index}
              className="banner"
              src={require(`../../images/${category.category_image}`)}
              onClick={() =>
                this.props.history.push(`/errors/${category.category_name}`)
              }
            />
          );
        })}
      </div>
    );
  }
}

export default ChooseErrorCategoryPage;
