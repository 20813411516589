import React, { Component } from "react";
import "./hexagon.scss";

class Hexagon extends Component {
  state = {};
  render() {
    return (
      <span className="hexagon-container">
        <div className={"hexagon " + (this.props.current ? "current" : "")} />
        <span
          className="number"
          style={this.props.number > 9 ? { right: "-4px" } : { right: "-7px" }}
        >
          {this.props.number}
        </span>
      </span>
    );
  }
}

export default Hexagon;
