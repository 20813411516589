import React, { Component } from "react";
import "./search-input.scss";

class SearchInput extends Component {
  state = {};
  render() {
    return (
      <div className="search">
        <input
          name={this.props.name}
          onChange={this.props.onChange}
          value={this.props.value}
          type="text"
          style={this.props.style}
          className={"search-input"}
          placeholder="Search"
        />
        <img
          className="search-icon"
          src={require("../../images/search-icon.png")}
        />
      </div>
    );
  }
}

export default SearchInput;
