import React, { Component, useState, useEffect, useContext } from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { useRouteMatch } from "react-router";
import AppDataContext from "../../context/appDataContext";
import "./login-page.scss";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Api from "../../utils/api";
import { set } from "react-ga";
const LoginPage = (props) => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [countryCode, setCountryCode] = useState("972");
  const [showError, setShowError] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [otp, setOtp] = useState("");
  const [loading, setLoading] = useState(false);

  const AppData = useContext(AppDataContext);

  let matchOTP = useRouteMatch({
    path: "/otp",
    strict: true,
    sensitive: true,
  });
  let matchOTPcode = useRouteMatch({
    path: "/otp/:otpcode",
    strict: true,
    sensitive: true,
  });
  useEffect(() => {
    if (matchOTPcode) {
      setOtp(matchOTPcode.params.otpcode);
      Otp(matchOTPcode.params.otpcode);
      //alert();
    }
  }, []);
  const Login = async () => {
    //console.log("get Token...");
    let token;
    setLoading(true);
    setShowError(false);
    try {
      token = await executeRecaptcha("login_page");
    } catch (err) {
      //console.log(err);
      alert("ERROR");
      return;
    }

    //console.log("token", token);
    Api.post("Login/Form", {
      PhoneNumber: phoneNumber.slice(countryCode.length),
      CountryCode: countryCode,
      "g-recaptcha-response": token,
    }).then(async (res) => {
      //console.log("sent otp");
      const {
        data: { Success, Payload, Message, ErrorCode },
      } = res;
      setLoading(false);
      if (Success) {
        props.history.push("/otp");
        if ("OTPCredential" in window) {
          navigator.credentials
            .get({
              otp: {
                transport: ["sms"],
              },
            })
            .then((get_otp) => {
              if (get_otp != null) {
                setOtp(get_otp.code);
                Otp(get_otp.code);
              }
            });
          //alert(content);
        }
      } else {
        setShowError(true);

        if (ErrorCode === 5) {
          setErrorText("Incorrect Number");
          //setPhoneNumber("");
        } else if (ErrorCode === 901) {
          setErrorText("Recaptcha Verification Failed");
        }
      }
    });
  };
  ///choose-error-category
  const Otp = async (otpCode) => {
    setShowError(false);
    setLoading(true);
    const token = await executeRecaptcha("login_page");
    Api.post("Login/OTP", {
      otp: otpCode,
      "g-recaptcha-response": token,
    }).then((res) => {
      const {
        data: { Success, PayLoad, Message, ErrorCode },
      } = res;
      setLoading(false);
      if (Success) {
        localStorage.setItem("name", PayLoad);
        AppData.goINI();
        props.history.push("/choose-error-category");
      } else {
        setShowError(true);

        if (ErrorCode === 5) {
          setErrorText("Incorrect Number");
          setPhoneNumber("");
        } else if (ErrorCode === 901) {
          setErrorText("Recaptcha Verification Failed");
        } else if (ErrorCode === 130) {
          setErrorText("Wrong code, try again");
        } else if (ErrorCode === 131) {
          setErrorText("Expired code, start over");
          props.history.push("/");
        }
      }
    });
  };
  return (
    <div className="login-page">
      <div className="upper-half">
        <img className="logo" src={require("../../images/login-logo.png")} />
      </div>
      <div className="lower-half">
        <div className="lower-half-content">
          <div className="error-text">
            {showError && <span>{errorText}</span>}
          </div>
          {!matchOTP ? (
            <React.Fragment>
              <PhoneInput
                disabled={loading}
                containerClass="phone"
                inputClass="phone-input"
                buttonClass="phone-button"
                dropdownClass="phone-select"
                enableSearch={true}
                countryCodeEditable={false}
                country={"il"}
                value={phoneNumber}
                onChange={(phone, country) => {
                  setCountryCode(country.dialCode);
                  setPhoneNumber(phone);
                }}
              />
              {/* <input
                name="phone"
                type="text"
                placeholder="Enter your phone"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
              /> */}
              {/* <input type="password" placeholder="Password" /> */}
              <button disabled={loading} onClick={() => Login()}>
                Send OTP
              </button>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <input
                disabled={loading}
                inputmode="numeric"
                pattern="[0-9]*"
                maxLength="6"
                autocomplete="one-time-code"
                className="otp-field"
                type="text"
                name="otp"
                placeholder="Enter code"
                value={otp}
                onChange={(e) => {
                  const val = e.target.value;
                  if (val.length <= 6) setOtp(val);
                  if (val.length === 6) {
                    Otp(val);
                  }
                }}
              />
              {/* <input type="password" placeholder="Password" /> */}
              <button disabled={loading} onClick={() => Otp(otp)}>
                Enter
              </button>
            </React.Fragment>
          )}
          <div className="google-recaptcha">
            This site is protected by reCAPTCHA and the Google
            <br />
            <a href="https://policies.google.com/privacy">
              Privacy Policy
            </a> and{" "}
            <a href="https://policies.google.com/terms">Terms of Service</a>{" "}
            apply.
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
