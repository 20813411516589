import React, { useEffect, useState } from "react";
import "./App.scss";
import {
  Route,
  Switch,
  HashRouter,
  Redirect,
  useLocation,
  useHistory,
} from "react-router-dom";
import LoginPage from "./pages/LoginPage/login-page";
import ChooseErrorCategoryPage from "./pages/ChooseErrorCategoryPage/choose-error-category-page";
import ErrorsPage from "./pages/ErrorsPage/errors-page";
import ErrorPage from "./pages/ErrorPage/error-page";
import LogoutPage from "./pages/LogoutPage/logout-page";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { AppDataProvider } from "./context/appDataContext";
import Api from "./utils/api";
import Axios from "axios";
// import MainErrors from "./data/main-errors.json";
// import ErrorCategories from "./data/error-categories.json";
// import SubErrors from "./data/sub-errors.json";
import ReactGA from "react-ga";
ReactGA.initialize("UA-176815259-1", { debug: true });
/*
const history = createHistory()
ReactGA.initialize('UA-00000000-1');
history.listen((location, action) => {
    ReactGA.pageview(location.pathname + location.search);
    console.log(location.pathname)
});*/

function App() {
  const [gotInI, setGotInI] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [fullName, setFullName] = useState("");
  const [data, setData] = useState({
    ErrorCategories: [],
    SubErrors: [],
    MainErrors: [],
  });
  const GetData = () => {
    Promise.all([
      Axios.get("/files/JSON/error-categories.json"),
      Axios.get("/files/JSON/main-errors.json"),
      Axios.get("/files/JSON/sub-errors.json"),
    ]).then(function (results) {
      const ErrorCategories = results[0].data;

      const MainErrors = results[1].data;
      const SubErrors = results[2].data;
      setData({
        ErrorCategories,
        MainErrors,
        SubErrors,
      });
      setGotInI(true);
    });
    /*
    
    setTimeout(() => {
      setData({
        ErrorCategories,
        MainErrors,
        SubErrors,
      });
      setGotInI(true);
    }, 100);*/
  };
  const Ini = () => {
    Api.get("CheckLogin", { withCredentials: true })
      .then((res) => {
        const {
          data: { Success, Payload, Message, ErrorCode },
        } = res;
        if (Success) {
          setIsLoggedIn(true);
          setFullName(Payload.FullName);
          // 'dimension1', dimensionValue);
          ReactGA.set({
            dimension1: `+${Payload.Phone.CountryCode}.${Payload.Phone.Number}`,
          });
          GetData();
        } else setIsLoggedIn(false);
      })
      .catch((error) => {
        setIsLoggedIn(false);
        setGotInI(true);
      });
  };
  const history = useHistory();
  useEffect(() => {
    //ReactGA.initialize("UA-176815259-1");

    history.listen((location) => {
      console.log(`You changed the page to: ${location.pathname}`);
      //alert(location.pathname);
      ReactGA.pageview(location.pathname);
    });
    ReactGA.pageview(window.location.pathname + window.location.search);
    Ini();
  }, []);
  //usePageViews();
  return (
    gotInI && (
      <GoogleReCaptchaProvider reCaptchaKey="6LeE58MZAAAAAFMcH5wx7iaqN6GKjuNj6J0hRqBS">
        <AppDataProvider
          value={{
            IsLoggedIn: isLoggedIn,
            FullName: fullName,
            Data: data,
            goINI: Ini,
          }}
        >
          {/*<HashRouter> <TopBar /> */}
          <Switch>
            <Route
              exact
              path={["/", "/otp/:otpcode?"]}
              render={(props) =>
                isLoggedIn ? (
                  <Redirect to="/choose-error-category" />
                ) : (
                  <LoginPage {...props} />
                )
              }
            />
            <Route
              exact
              path="/choose-error-category"
              render={(props) =>
                !isLoggedIn ? (
                  <Redirect to="/" />
                ) : (
                  <ChooseErrorCategoryPage {...props} />
                )
              }
            />
            <Route exact path="/errors/:category_name" component={ErrorsPage} />
            <Route
              exact
              path="/errors/:category_name/:main_error_id/:error_name?"
              render={(props) =>
                !isLoggedIn ? <Redirect to="/" /> : <ErrorPage {...props} />
              }
            />
            <Route
              exact
              path="/logout"
              render={(props) => <LogoutPage {...props} />}
            />
          </Switch>
        </AppDataProvider>
      </GoogleReCaptchaProvider>
    )
  );
}
//

export default App;
